import React, { FC } from 'react';
import { navigate } from 'gatsby';
import NiceCard from 'components/NiceCard';
import { RelatedArticlesItemProps } from './models';

const RelatedArticlesItem: FC<RelatedArticlesItemProps> = ({
  title,
  description,
  imageAlt,
  image,
  url,
  relatedArticlesButton,
}) => {
  const bottomCardContent = {
    title,
    description,
    buttons: [
      {
        ...relatedArticlesButton,
        onClick: () => navigate(url),
      },
    ],
  };

  const topCardContent = {
    cardImage: {
      imageAlt,
      image,
    },
  };

  return <NiceCard {...{ bottomCardContent, topCardContent }} />;
};

export default RelatedArticlesItem;
