export interface ArticleProps {
  title: string;
  titleUnderline?: '0' | '1';
  content?: string;
  contentWithImages?: PageContent.ContentWithImagesType[];
  articleBannerImage: PageContent.UmbracoImageType;
  articleImageAlt: string;
  articleBannerVideo?: PageContent.IframeType[];
  articleBackgroundColor?: PageContent.ColorPickerType;
  lang: string;
}

export enum STRUCTURE {
  RTE = 'RTE',
  IMAGE = 'Image',
}
