import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Article from 'components/Article';
import RelatedProducts from 'components/RelatedProducts';
import RelatedArticles from 'components/RelatedArticles';
import ArticleDate from 'components/ArticleDate';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { ArticlePageProps } from './models';
import './articlePage.scss';

const ArticlePage: FC<ArticlePageProps> = ({
  data: {
    articlePage: {
      title,
      spareBreadcrumbName,
      titleUnderline,
      content,
      contentWithImages,
      relatedProductsForArticles,
      articleBannerImage,
      articleImageAlt,
      articleBannerVideo,
      articleBackgroundColor,
      relatedArticlesTitle,
      langProps: { urls, lang },
      seoProps: {
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      },
      createDate,
      updateDate,
    },
    allSitePage: { nodes },
    relatedProductsList,
    tipsAndTricks,
    relatedArticlesList: { relatedArticlesNodes },
    articleConfigs,
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(
      crumbs,
      areBreadcrumbsDisplayed,
      nodes,
      pathname,
      spareBreadcrumbName
    ),
  };

  return (
    <Layout className="article-page" breadcrumbs={breadcrumbs} langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Article
        {...{
          articleBannerImage,
          articleImageAlt,
          articleBannerVideo,
          articleBackgroundColor,
          title,
          titleUnderline,
          content,
          contentWithImages,
          lang,
        }}
      />
      {articleConfigs ? (
        <ArticleDate
          articleConfigs={articleConfigs}
          createDate={createDate}
          updateDate={updateDate}
          lang={lang}
        />
      ) : null}
      {relatedProductsList?.relatedProductsNodes.length && relatedProductsForArticles ? (
        <RelatedProducts
          {...{
            relatedProductsNodes: relatedProductsList.relatedProductsNodes,
            relatedProductsTitle: relatedProductsForArticles.relatedProductsTitle,
          }}
        />
      ) : null}
      {relatedArticlesNodes ? (
        <RelatedArticles
          {...{
            relatedArticles: relatedArticlesNodes,
            tipsAndTricks,
            relatedArticlesTitle,
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query(
    $url: String!
    $lang: String
    $relatedProductsValues: [String]
    $relatedUrlArticles: [String]
  ) {
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
          buyNow {
            link {
              url
              target
              name
            }
            ariaLabel
            text
            withReverse
          }
        }
        productTitle
        productTitleLong
        productTitleShort
        productDescriptionLong
        productTitleWithSize
        url
      }
    }
    articlePage: umbracoArticles(url: { eq: $url }) {
      url
      createDate
      updateDate
      title
      spareBreadcrumbName
      titleUnderline
      content
      contentWithImages {
        ...contentWithImages
      }
      relatedProductsForArticles {
        relatedProductsTitle
      }
      articleBannerImage {
        ...umbracoImage
      }
      relatedArticlesTitle
      articleImageAlt
      articleBannerVideo {
        link
        title
        autoplay
        image {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            alias
            fallbackQuery
            type
            url
          }
        }
      }
      articleBackgroundColor {
        label
        value
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seoProps {
        seoMetaTitle
        seoMetaKeywords
        seoMetaDescription
        seoExternalHreflangs {
          key
          value
        }
        ogImage
      }
    }
    tipsAndTricks: allUmbracoTipsAndTricks(filter: { langProps: { lang: { eq: $lang } } }) {
      relatedArticlesButtonNodes: nodes {
        relatedArticlesButton {
          ariaLabel
          text
        }
      }
    }
    relatedArticlesList: allUmbracoArticles(
      filter: { url: { in: $relatedUrlArticles }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedArticlesNodes: nodes {
        title
        teaser
        url
        articleBannerImageAltText
        articleBannerImage {
          ...umbracoImage
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
    articleConfigs(lang: { eq: $lang }) {
      ...FragmentArticleConfigs
    }
  }
`;

export default ArticlePage;
