import React, { FC } from 'react';
import RelatedProductsItem from './RelatedProductsItem/index';

import { RelatedProductsProps } from './models';

import './RelatedProducts.scss';

const RelatedProducts: FC<RelatedProductsProps> = ({
  relatedProductsNodes,
  relatedProductsTitle,
}) => (
  <div className="related-products" data-testid="related-products">
    <h2 className="related-products__title">{relatedProductsTitle}</h2>
    <div className="related-products__items">
      {relatedProductsNodes.map((relatedProductsNode) => (
        <RelatedProductsItem key={relatedProductsNode.productTitle} {...relatedProductsNode} />
      ))}
    </div>
  </div>
);

export default RelatedProducts;
