import React, { FC } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import UmbracoImage from 'common/Image/UmbracoImage';
import Button from 'common/Button';
import { NiceCardProps } from './models';
import ButtonPosition from './CardButtonPositionEnum';

import './NiceCard.scss';
import './MainNiceCardOverride.scss';

const NiceCard: FC<NiceCardProps> = ({ topCardContent, bottomCardContent }) => {
  const { cardImage, isColorOverlay, isVideoOverlay, customClasses } = topCardContent;
  const { imageAlt, image } = cardImage;
  const {
    title,
    titleClassname,
    descriptionClassname,
    description,
    buttons,
    isPinkBackgroundColor,
    titleAsLink,
    url,
  } = bottomCardContent;

  const createTitle = () => {
    return titleAsLink ? (
      <Link aria-label={title} to={url!}>
        {title}
      </Link>
    ) : (
      <h2>{title}</h2>
    );
  };

  return (
    <div className={classnames('card', { card__animation: isVideoOverlay })}>
      <div className="card__top">
        <UmbracoImage
          image={image}
          alt={imageAlt!}
          className={classnames(customClasses, {
            'card__top--color-overlay': isColorOverlay,
            'card__top--video-overlay': isVideoOverlay,
          })}
        />
      </div>
      <div
        className={classnames('card__bottom', {
          'card__bottom--pink-background': isPinkBackgroundColor,
        })}
      >
        {title ? <div className={titleClassname}>{createTitle()}</div> : null}
        {description ? (
          <div className={descriptionClassname}>
            <p>{description}</p>
          </div>
        ) : null}
        {buttons.map(({ className, text, ariaLabel, position, onClick, variant, buttonAsLink }) => {
          return buttonAsLink ? (
            <Link
              key={text}
              className={classnames(`align-self-${position || ButtonPosition.Left} ${className}`)}
              aria-label={ariaLabel}
              to={url!}
            >
              {text}
            </Link>
          ) : (
            <Button
              key={text}
              isReversed={variant?.reversed}
              className={classnames(`align-self-${position || ButtonPosition.Left}`)}
              ariaLabel={ariaLabel}
              onClick={onClick}
            >
              {text}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default NiceCard;
