import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SocialMedia from 'common/SocialMedia';

const SocialMediaShares = ({ socialMediaSharesLang }) => {
  const {
    allSocialMediaShares: { nodes },
  }: PageContent.SocialMediaSharesType = useStaticQuery(graphql`
    {
      allSocialMediaShares {
        nodes {
          lang
          socialMediaTitle
          socialMediaItems {
            properties {
              socialMediaIcon
              socialMediaAriaLabel
              isVisible
              socialMediaIconColor {
                label
                value
              }
              socialMediaHoverIconColor {
                label
                value
              }
              socialMediaLink {
                name
                target
                url
                icon
                published
              }
              socialMediaShareLink
            }
          }
        }
      }
    }
  `);
  const { socialMediaItems, socialMediaTitle } = nodes.filter(
    ({ lang }) => lang === socialMediaSharesLang
  )[0];

  return <SocialMedia {...{ socialMediaItems, socialMediaTitle }} />;
};

export default SocialMediaShares;
