import React, { FC } from 'react';
import RelatedArticlesItem from './RelatedArticlesItem';

import { RelatedArticlesProps } from './models';
import './RelatedArticles.scss';

const RelatedArticles: FC<RelatedArticlesProps> = ({
  relatedArticles,
  tipsAndTricks: {
    relatedArticlesButtonNodes: [{ relatedArticlesButton }],
  },
  relatedArticlesTitle,
}) => (
  <div className="related-articles">
    {relatedArticlesTitle ? (
      <h2 className="related-articles__title">{relatedArticlesTitle}</h2>
    ) : null}
    <div className="related-articles__cards">
      {relatedArticles.map(
        ({
          title,
          teaser,
          articleBannerImageAltText,
          articleBannerImage,
          url,
        }: PageContent.RelatedArticlesType) => (
          <RelatedArticlesItem
            key={title}
            {...{
              title,
              description: teaser,
              imageAlt: articleBannerImageAltText,
              image: articleBannerImage,
              url,
              relatedArticlesButton,
            }}
          />
        )
      )}
    </div>
  </div>
);

export default RelatedArticles;
